import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06087d01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ProductBox" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "info" }
const _hoisted_5 = { class: "name mb-4" }
const _hoisted_6 = { class: "desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "img-fluid",
        src: _ctx.imageSrc
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Typography, {
          size: "l",
          "should-adjust": "",
          bold: ""
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "name", {}, undefined, true)
          ]),
          _: 3
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Typography, {
          size: "md",
          "should-adjust": ""
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "desc", {}, undefined, true)
          ]),
          _: 3
        })
      ])
    ])
  ]))
}