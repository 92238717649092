
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { brandOrNonStr, isBrand } from "@/function/switchBrandName";

@Options({
  components: { Typography },
  methods: { brandOrNonStr, isBrand },
})
export default class HomeView extends Vue {}
