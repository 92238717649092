import { isBrand } from "@/function/switchBrandName";

export type TMenuListValue = { label: string; to: string };
type TMenuListKey =
  | "home"
  | "products"
  | "radiationInfo"
  | "safety"
  | "mineralFibers"
  | "radiationHormesis"
  | "clinicalTrial"
  | "otherFunctions"
  | "company";

const menuListBrand: { [key in TMenuListKey]: TMenuListValue } = {
  home: { label: "トップページ", to: "home" },
  mineralFibers: { label: "鉱石系繊維について", to: "mineralFibers" },
  radiationInfo: { label: "放射線の基礎知識", to: "radiationInfo" },
  radiationHormesis: { label: "放射性ホルミシス", to: "radiationHormesis" },
  safety: { label: "安全性の追求", to: "safety" },
  clinicalTrial: { label: "臨床試験", to: "clinicalTrial" },
  otherFunctions: { label: "その他機能", to: "otherFunctions" },
  products: { label: "製品一覧", to: "products" },
  company: { label: "会社概要", to: "company" },
};

const { products, ...menuListNonBrand } = menuListBrand;

export const menuList = isBrand() ? menuListBrand : menuListNonBrand;
