
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import Menu from "@/components/menu/Menu.vue";
import { PropType } from "vue";
import { TMenuListValue } from "@/components/menu/menuList";

@Options({
  components: { Menu, Typography },
  props: {
    title: { type: String, required: true },
    headerSrc: { type: String, required: true },
    titleColor: { type: String as PropType<"white" | "dark">, required: true },
    next: Object as PropType<TMenuListValue>,
    prev: Object as PropType<TMenuListValue>,
  },
})
export default class Content extends Vue {
  title!: string;
  headerSrc!: string;
  titleColor!: string;
  next!: TMenuListValue;
  prev!: TMenuListValue;

  get eyeCatchStyle() {
    return {
      backgroundImage: `url(${this.headerSrc})`,
    };
  }

  get titleStyle() {
    switch (this.titleColor) {
      case "white":
      default:
        return { color: "white" };
      case "dark":
        return { color: "black" };
    }
  }
}
