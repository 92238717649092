import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProductsView from "@/views/ProductsView.vue";
import SafetyView from "@/views/SafetyView.vue";
import MineralFibersView from "@/views/MineralFibersView.vue";
import RadiationHormesisView from "@/views/RadiationHormesisView.vue";
import ClinicalTrialView from "@/views/ClinicalTrialView.vue";
import OtherFunctionsView from "@/views/OtherFunctionsView.vue";
import CompanyView from "@/views/CompanyView.vue";
import RadiationInfoView from "@/views/RadiationInfoView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/products",
    name: "products",
    component: ProductsView,
  },
  {
    path: "/contents/radiationInfo",
    name: "radiationInfo",
    component: RadiationInfoView,
  },
  {
    path: "/contents/safety",
    name: "safety",
    component: SafetyView,
  },
  {
    path: "/contents/mineralFibers",
    name: "mineralFibers",
    component: MineralFibersView,
  },
  {
    path: "/contents/radiationHormesis",
    name: "radiationHormesis",
    component: RadiationHormesisView,
  },
  {
    path: "/contents/clinicalTrial",
    name: "clinicalTrial",
    component: ClinicalTrialView,
  },
  {
    path: "/contents/otherFunctions",
    name: "otherFunctions",
    component: OtherFunctionsView,
  },
  {
    path: "/company",
    name: "company",
    component: CompanyView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: async (to, from, savedPosition) => {
    const findEl = async (hash: string, x = 1) => {
      return (
        document.querySelector(hash) ||
        new Promise((resolve) => {
          if (x > 50) {
            return resolve;
          }
          setTimeout(() => {
            console.log(hash);
            resolve(findEl(hash, ++x || 1));
          }, 100);
        })
      );
    };

    if (to.hash) {
      const el = await findEl(to.hash);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return window.scrollTo({ top: el?.offsetTop - 50, behavior: "smooth" });
    }

    // always scroll to top
    return { top: 0 };
  },
});

export default router;
