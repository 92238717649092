
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import Menu from "@/components/menu/Menu.vue";
import Content from "@/components/Content.vue";
import { menuList } from "@/components/menu/menuList";
import { isBrand } from "@/function/switchBrandName";

@Options({
  components: { Content, Menu, Typography },
  methods: { isBrand },
})
export default class OtherFunctionsView extends Vue {
  headerSrc = require("../assets/img--content--radiationHormesis.jpeg");
  title = menuList.otherFunctions.label;
  prev = menuList.clinicalTrial;
}
