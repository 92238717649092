
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import ProductBox from "@/components/ProductBox.vue";

@Options({
  components: { ProductBox, Typography },
})
export default class ProductsView extends Vue {
  neoSrc = require("/src/assets/img--prod__holmic-neo.jpg");
  alphaSrc = require("/src/assets/img--prod__holmic-alpha.jpg");
  materialSrc = require("/src/assets/img--prod__holmic-material.jpg");
  oemSrc = require("/src/assets/img--prod__holmic-oem.jpg");
}
