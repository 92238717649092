<template>
  <div :class="{ isBigFont: isBigFont }">
    <Header />
    <router-view />
    <Menu />
    <Footer />
    <div class="fix-cont">
      <div class="big-font-btn" @click="isBigFont = !isBigFont">
        {{ isBigFont ? "文字を小さく" : "文字を大きく" }}
      </div>

      <div class="to-top" @click="toTop">
        <img src="./assets/icon--arrow__white.svg" class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "style/normalize";
@import "style/media";

#app {
  font-family: "Hiragino Kaku Gothic Pro", Avenir, Helvetica, Arial, sans-serif;
  letter-spacing: 0.07rem;
  font-size: 16px;

  @include mq(sm) {
    font-size: 14px;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-blue-alt: #34475a;
  --color-white-blue: #eff1f5;

  --width-content: 1200px;

  --font-size-rem__xs: 0.7rem;
  --font-size-rem__s: 0.8rem;
  --font-size-rem__md: 1rem;
  --font-size-rem__l: 1.2rem;
  --font-size-rem__xl: 1.6rem;

  .isBigFont {
    --font-size-rem__xs__big: 0.9rem;
    --font-size-rem__s__big: 1rem;
    --font-size-rem__md__big: 1.2rem;
    --font-size-rem__l__big: 1.4rem;
    --font-size-rem__xl__big: 1.6rem;
  }

  .fix-cont {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
  }

  .big-font-btn {
    border-radius: 6px;
    background-color: black;
    display: flex;
    height: 50px;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    font-weight: bold;
    margin-right: 16px;

    &:hover {
      opacity: 0.5;
    }
  }

  .to-top {
    border-radius: 6px;
    background-color: #42b983;
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    img {
      transform: rotate(-90deg);
    }
  }
}
</style>

<script>
import Header from "@/components/Header";
import { Options, Vue } from "vue-class-component";
import Footer from "@/components/Footer";
import Menu from "@/components/menu/Menu";
import Typography from "@/components/Typography";
import { isBrand } from "@/function/switchBrandName";

@Options({
  methods: { isBrand },
  components: { Typography, Menu, Footer, Header },
})
export default class App extends Vue {
  isBigFont = false;

  toTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  mounted() {
    console.log(process.env.VUE_APP_BRAND);
    document.title = isBrand() ? "HOLMIC" : "HORMESIS-MATERIAL";

    const link = document.querySelector("link[rel*='icon']");
    link.href =
      process.env.BASE_URL +
      (isBrand() ? "fav-brand.ico" : "fav-non-brand.ico");
  }
}
</script>
