
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import Menu from "@/components/menu/Menu.vue";
import router from "@/router";
import { Watch } from "vue-property-decorator";
import { isBrand } from "@/function/switchBrandName";

@Options({
  components: { Menu, Typography },
  props: {},
  methods: { isBrand },
})
export default class Header extends Vue {
  isOpenMenu = false;

  onClickMenuBtn() {
    this.isOpenMenu = !this.isOpenMenu;
  }

  @Watch("isOpenMenu")
  onChangeIsOpenMenu(v: boolean) {
    if (v) {
      document.addEventListener("touchmove", this.handleMove, {
        passive: false,
      });
      document.addEventListener("mousewheel", this.handleMove, {
        passive: false,
      });

      window.scrollTo(0, 0);
    } else {
      document.removeEventListener("touchmove", this.handleMove);
      document.removeEventListener("mousewheel", this.handleMove);
    }
  }

  handleMove(e: Event) {
    e.preventDefault();
  }

  created() {
    router.afterEach(() => {
      this.isOpenMenu = false;
    });
  }
}
