
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Prop } from "vue-property-decorator";

@Options({
  components: { Typography },
})
export default class ProductBox extends Vue {
  @Prop({ required: true })
  imageSrc!: string;
}
