
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import Menu from "@/components/menu/Menu.vue";
import Content from "@/components/Content.vue";
import { menuList } from "@/components/menu/menuList";

@Options({
  components: { Content, Menu, Typography },
})
export default class RadiationInfoView extends Vue {
  headerSrc = require("../assets/sun-ge99e89cdf_1920.jpg");
  title = menuList.radiationInfo.label;
  next = menuList.radiationHormesis;
  prev = menuList.mineralFibers;
}
