
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { brandOrNonStr } from "@/function/switchBrandName";
import { menuList } from "@/components/menu/menuList";

@Options({
  components: { Typography },
  props: {},
  methods: { brandOrNonStr },
})
export default class Footer extends Vue {
  menuList = menuList;
}
