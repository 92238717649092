
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { menuList } from "@/components/menu/menuList";
import MenuButton from "@/components/menu/MenuButton.vue";

@Options({
  components: { MenuButton, Typography },
  props: {},
})
export default class Menu extends Vue {
  menuList = menuList;
}
