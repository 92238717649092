
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import ProductBox from "@/components/ProductBox.vue";
import { isBrand } from "@/function/switchBrandName";

@Options({
  components: { ProductBox, Typography },
})
export default class CompanyView extends Vue {
  aboutCompanyCont: { name: string; cont: string }[] = [
    {
      name: "社名",
      cont: "株式会社エスカコーポレーション",
    },
    {
      name: "代表者",
      cont: "中野　広典",
    },
    {
      name: "サイト運営責任者",
      cont: "畑田　浩伸",
    },
    {
      name: "住所",
      cont: "〒675-0016　兵庫県加古川市野口町長砂629-8\nTEL：079-420-5211\nFAX：079-420-5333",
    },
    {
      name: "資本金",
      cont: "1000万円",
    },
    {
      name: "決算",
      cont: "8月（年1回）",
    },
    {
      name: "従業員",
      cont: "7名",
    },
    {
      name: "年商",
      cont: "7億5千万円",
    },
    {
      name: "取扱商品",
      cont: "ポリエステル短繊維・不織布・オリジナル資材・商品開発",
    },
    {
      name: "主要得意先",
      cont: "寝装・インテリアメーカー",
    },
    {
      name: "主要仕入先",
      cont: "東レ、東レインターナショナル、海外メーカー",
    },
    {
      name: "取引銀行",
      cont: "みなと銀行加古川支店\n但馬銀行",
    },
  ];

  historyCompanyCont: { name: string; cont: string }[] = [
    {
      name: "1987年8月",
      cont: "兵庫県加古川市西条山手にてエスカコーポレーションを個人創業",
    },
    {
      name: "1996年6月",
      cont: "株式会社エスカコーポレーション設立",
    },
    {
      name: "1996年9月",
      cont: "兵庫県加古川市野口町長砂に事務所移転",
    },
    {
      name: "2001年8月",
      cont: isBrand()
        ? "健康資材「e-ion sheet」の開発をスタート"
        : "鉱石繊維開発スタート",
    },
    {
      name: "2002年7月",
      cont: "再生ポリエステル原料の取り扱いを開始",
    },
    {
      name: "2006年8月",
      cont: isBrand()
        ? "e-ion sheet改め「HOLMIC」として販売\n大阪府立大学名誉教授清水教永先生により臨床試験を実施"
        : "鉱石繊維販売開始\n公立大学にて臨床試験を実施",
    },
    {
      name: "2007年10月",
      cont: isBrand()
        ? "HOLMIC製造特許取得（特許第4027158号）"
        : "鉱石繊維製造特許取得（特許第4027158号）",
    },
    {
      name: "2016年4月",
      cont: "中野広典　代表取締役に就任",
    },
  ];
}
