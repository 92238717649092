
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import Menu from "@/components/menu/Menu.vue";
import Content from "@/components/Content.vue";
import { menuList } from "@/components/menu/menuList";
import { isBrand } from "@/function/switchBrandName";

@Options({
  components: { Content, Menu, Typography },
})
export default class ClinicalTrialView extends Vue {
  headerSrc = require("../assets/img--content--clinicalTrial.jpeg");
  title = menuList.clinicalTrial.label;
  next = menuList.otherFunctions;
  prev = menuList.safety;

  slideSrc = isBrand()
    ? "//www.slideshare.net/slideshow/embed_code/key/EeB4U7ST89ZoNi"
    : "//www.slideshare.net/slideshow/embed_code/key/COazq8IuNyXvMN";
}
