
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import Menu from "@/components/menu/Menu.vue";
import Content from "@/components/Content.vue";
import { menuList } from "@/components/menu/menuList";

@Options({
  components: { Content, Menu, Typography },
})
export default class SafetyView extends Vue {
  headerSrc = require("../assets/img--content--safety.jpg");
  title = menuList.safety.label;
  next = menuList.clinicalTrial;
  prev = menuList.radiationHormesis;
}
