
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";

@Options({
  components: { Typography },
  props: { to: { type: String, required: true } },
})
export default class MenuButton extends Vue {
  to!: string;
}
