
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import Menu from "@/components/menu/Menu.vue";
import Content from "@/components/Content.vue";
import { menuList } from "@/components/menu/menuList";

@Options({
  components: { Content, Menu, Typography },
})
export default class MineralFibersView extends Vue {
  headerSrc = require("../assets/img--content--mineralFibers.jpg");
  title = menuList.mineralFibers.label;
  next = menuList.radiationInfo;
}
